const baseColumn = vm => {
  return [{
      keyId: 1,
      title: '姓名',
      field: 'name',
      width: 200,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 2,
      title: '手机号',
      field: 'phone',
      width: 200,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 3,
      title: '所属部门',
      field: 'depts',
      width: 200,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 4,
      title: '登录账号',
      field: 'account',
      width: 200,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 5,
      title: '账号状态',
      field: 'status',
      width: 200,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: true,
      selectOptions: [{
          label: '正常',
          value: 'ENABLE'
        },
        {
          label: '禁用',
          value: 'DISABLE'
        }
      ],
      fieldOperateTag: {
        ENABLE: 'success',
        DISABLE: 'warning'
      },
      fieldEnumValueList: {
        ENABLE: '正常',
        DISABLE: '禁用'
      },
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 6,
      title: '角色',
      field: 'roles',
      width: 200,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 7,
      title: '上次登录时间',
      field: 'lastLoginTime',
      width: 200,
      fieldType: null,
      searchType: 'datetimerange',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    }
  ]
}

// 操作按钮配置项
const operateList = vm => {
  return [{
    name: '创建账号',
    btnFn: vm.addRow,
    plain: true,
    icon: 'el-icon-circle-plus'
  }]
}
// 搜索字段配置项
const searchFields = vm => {
  return [{
      keyId: 1,
      title: '姓名',
      field: 'name',
      width: 200,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: true, //是否排序
      copy: true //是否拥有复制功能
    },
    {
      keyId: 3,
      title: '账号状态',
      field: 'status',
      width: 200,
      searchType: 'select',
      searchValue: '', //搜索绑定值
      renderType: 'status', //achnor,status,option
      sortable: true, //是否排序
      textColor: '#e6a23c',
      copy: true, //是否拥有复制功能
      selectOptions: [{
          label: '正常',
          value: 'ENABLE'
        },
        {
          label: '禁用',
          value: 'DISABLE'
        }
      ]
    },
    {
      keyId: 3,
      title: '手机号',
      field: 'phone',
      width: 200,
      searchType: 'input',
      searchValue: '', //搜索绑定值
      sortable: false, //是否排序
      copy: true //是否拥有复制功能
    }
  ]
}
// 表格列配置
const tableFields = vm => {
  return [{
      keyId: 1,
      title: '姓名',
      field: 'name',
      width: 200,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 2,
      title: '手机号',
      field: 'phone',
      width: 200,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 3,
      title: '所属部门',
      field: 'depts',
      width: 200,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 4,
      title: '登录账号',
      field: 'account',
      width: 200,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 5,
      title: '账号状态',
      field: 'status',
      width: 200,
      fieldType: null,
      searchType: 'select',
      searchValue: '',
      selectOptions: null,
      showField: true,
      enum: true,
      selectOptions: [{
          label: '正常',
          value: 'ENABLE'
        },
        {
          label: '禁用',
          value: 'DISABLE'
        }
      ],
      fieldOperateTag: {
        ENABLE: 'success',
        DISABLE: 'warning'
      },
      fieldEnumValueList: {
        ENABLE: '正常',
        DISABLE: '禁用'
      },
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      filterable: false,
      textColor: null,
      fixed: null,
      renderType: 'status',
      copy: true
    },
    {
      keyId: 6,
      title: '角色',
      field: 'roles',
      width: 200,
      fieldType: null,
      searchType: 'input',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    },
    {
      keyId: 7,
      title: '上次登录时间',
      field: 'lastLoginTime',
      width: 200,
      fieldType: null,
      searchType: 'datetimerange',
      searchValue: '',
      selectOptions: null,
      showField: true,
      sortable: false,
      multiField: false,
      fieldCanClick: false,
      textColor: null,
      fixed: null,
      renderType: null,
      copy: true
    }
  ]
}
export default {
  operateList,
  searchFields,
  tableFields,
  baseColumn
}