import request from '@/utils/http'

// 查询账号信息
export function queryList(params) {
  return request({
    url: '/user/findByPage',
    method: 'post',
    data:params
  })
}

/***
 * 用户详情
 */
 export function userDetails(id) {
  return request({
    url: '/user/details?id='+id,
    method: 'get'
  })
}

/***
 * 账号修改
 */
export function userEdit(params) {
  return request({
    url: '/user/edit',
    method: 'post',
    data: params
  })
}

/***
 * 账号新增
 */
 export function userSave(params) {
  return request({
    url: '/user/save',
    method: 'post',
    data: params
  })
}

/***
 * 账号启用、禁用
 */
 export function editStatus(params) {
  return request({
    url: '/user/updateStatus',
    method: 'get',
    params
  })
}

/***
 * 重置密码
 */
 export function resetPassword(params) {
  return request({
    url: '/user/resetPassword',
    method: 'get',
    params
  })
}



/***
 * 获取所有部门列表
 */
 export function getDeptList(params) {
  return request({
    url: '/dept/listAll',
    method: 'get',
    params
  })
}

/**
 * 获取角色列表
 * @param {获取角色列表} params
 * @returns
 */
export function getRoleList(params) {
  return request({
    url: '/role/list',
    method: 'get',
    params
  })
}


