<template>
  <div class="app-container">
    <div class="main-body">
      <el-row class="call-title">
        <el-col :span="24">
          <i class="lc-tms-iconyunweiguanli icon-cls icon-cls-green" />
          <span class="title">账号管理</span>
        </el-col>
      </el-row>
      <el-row>
        <search-table
          ref="searchTable"
          labelWidth="70px"
          :loading="listLoading"
          :searchFields="searchFields"
          :autoBtnList="autoBtnList"
          :colSize="colSize"
          :total="total"
          :tableData="dataList"
          :tableColumns="tableColumns"
          :baseColumns="baseColumns"
          :sortFields="sortFields"
          :checkboxColumn="true"
          :hasPagination="true"
          :showFooter="true"
          :showSetting="true"
          :showColumnSearch="true"
          :showSortable="true"
          :tableSummary="tableSummary"
          :proxyParams="proxyParams"
          :optionColumn="optionColumn"
          :selectTableSummary="selectTableSummary"
          @saveTableField="saveTableField"
          @saveSearchField="saveSearchField"
          @saveSortField="saveSortField"
          @getTableData="queryList"
          @editEvent="editEvent"
          @enableEvent="enableEvent"
          @disableEvent="disableEvent"
          @resetPassword="resetPassword"
        >
        </search-table>
      </el-row>
    </div>
    <el-dialog :visible.sync="dialogVisible" :title="dialogTitle" width="65%" :close-on-click-modal="false">
      <el-form :model="formData" ref="formData" :rules="rules" label-width="120px">
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="姓名：" prop="name">
              <el-input v-model="formData.name" size="small" class="input-width-9" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="邮箱：" prop="email">
              <el-input v-model="formData.email" size="small" class="input-width-9" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="12">
            <el-form-item label="手机号码：" prop="phone">
              <el-select clearable v-model="formData.phonePrefix" style="width: 40%" class="input-cls" size="small">
                <el-option v-for="item in phonePrefixList" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
              <el-input v-model="formData.phone" size="small" style="width: 55%" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="系统登录账号：" prop="account">
              <el-input v-model="formData.account" size="small" class="input-width-9" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="所属部门：" prop="deptIds">
              <treeselect
                v-model="deptsChecked"
                :multiple="true"
                size="small"
                class="input-width-9"
                :options="deptList"
                :flat="true"
                :disable-branch-nodes="true"
                search-nested
                @input="inputChange"
                :normalizer="normalizer"
                placeholder="请选择部门"
              />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="角色：" prop="roleIds">
              <el-select clearable multiple v-model="roles" placeholder="请选择" @change="roleChanged" class="input-cls input-width-9" size="small">
                <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitData">提 交</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { queryList, userDetails, getDeptList, getRoleList, editStatus, userEdit, resetPassword, userSave } from '@/api/sysUser'
import CalTableHeight from '@/mixins/calTableHeight'
import SearchTable from '@/components/LcSearchTable'
import config from '@/const/user/index.js'
import { columnSetting } from '@/api/common'
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
export default {
  components: { Treeselect, SearchTable },
  mixins: [CalTableHeight],
  computed: {
    // 操作按钮
    autoBtnList() {
      return config.operateList(this)
    }
  },
  data: function () {
    const validatPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('手机号不能为空'))
      } else {
        const reg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的手机号'))
        }
      }
    }
    const validatEmail = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('电子邮箱不能为空'))
      } else {
        const reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        if (reg.test(value)) {
          callback()
        } else {
          return callback(new Error('请输入正确的电子邮箱'))
        }
      }
    }

    return {
      total: 0,
      listLoading: false,
      deptsChecked: [],
      phonePrefixList: [
        { value: '+86', label: '+86' },
        { value: '+1', label: '+1' },
        { value: '+31', label: '+31' }
      ],
      roleList: [],
      formData: {
        id: '',
        phonePrefix: '+86',
        account: '', // 系统登录账号
        deptIds: '', //所属部门
        email: '', // 电子邮件
        name: '', //姓名
        phone: '', //电话
        roleIds: '' // 角色
      },
      rules: {
        phonePrefix: [{ required: true, message: '请选择', trigger: 'blur' }],
        account: [{ required: true, message: '请输入系统登录账号', trigger: 'blur' }], // 系统登录账号
        deptIds: [{ required: true, message: '请选择所属部门', trigger: 'blur' }], //所属部门
        email: [
          { required: true, message: '请输入电子邮件', trigger: 'blur' },
          {
            validator: validatEmail,
            message: '请输入正确的电子邮件',
            trigger: 'blur'
          }
        ], // 电子邮件
        name: [{ required: true, message: '请输入姓名', trigger: 'blur' }], //姓名
        phone: [
          { required: true, message: '请输入电话号码', trigger: 'blur' },
          {
            validator: validatPhone,
            message: '请输入正确的电话号码',
            trigger: 'blur'
          }
        ], //电话
        roleIds: [{ required: true, message: '请选择角色', trigger: 'blur' }] // 角色
      },
      // 查询条件
      queryParams: {
        current: 1,
        size: 100
      },
      dataList: [],
      deptList: [], //部门列表
      dialogVisible: false,
      dialogVisibleInfo: false,
      dialogTitle: '添加账号',
      roles: [],
      checkedRecords: [], // 表格已经选中的行数据
      baseColumn: [], //接口请求的表格配置
      baseColumns: [],
      tableColumns: [], //用户自定义的表格配置
      searchFields: [], //用户自定义的search配置
      tableSummary: {}, //后端返回的总合计数据
      selectTableData: [], //checkbox选中的表格数据
      colSize: 3, //用户设置展示列比例
      selectTableSummary: config.selectTableSummary, // 需要选中合计的字段
      sortFields: [], // 用户自定义的排序设置
      formParams: {}, // 当前查询的参数
      path: '/system/user/findByPage', // 更新字段名显示接口路径
      optionColumn: {
        title: '操作',
        width: 150,
        fixed: 'right',
        btnList: [
          {
            title: '编辑',
            enumOp: null,
            fn: 'editEvent',
            color: ''
          },
          {
            title: '停启用',
            valueField: 'status',
            enumOp: {
              DISABLE: {
                title: '启用',
                fn: 'enableEvent',
                color: 'darkgreen'
              },
              ENABLE: {
                title: '停用',
                fn: 'disableEvent',
                color: 'red'
              }
            },
            fn: null
          },
          {
            title: '重置密码',
            enumOp: null,
            fn: 'resetPassword',
            color: ''
          }
        ]
      }
    }
  },
  created() {},
  activated() {
    this.queryList()
    this.getDeptList()
    this.getRoleList()
  },
  mounted() {},
  methods: {
    // 新增
    addRow() {
      this.resetForm()
      this.dialogTitle = '添加账号'
      this.dialogVisible = true
    },
    resetPassword(row) {
      let params = { id: row.id }
      resetPassword(params).then(res => {
        if (res.code === 200) {
          this.$message.success('重置密码成功')
        } else {
          this.$message.success(res.msg)
        }
      })
    },
    // 编辑
    editEvent(row) {
      this.deptsChecked=[]
      this.roles=[]
      userDetails(row.id).then(res => {
        console.log(res)
        let data = res.data
        if (data.deptIds) {
          this.deptsChecked = data.deptIds.split(',')
        }
        if (data.roleIds) {
          this.roles = data.roleIds.split(',')
        }
        this.formData.id = data.id
        this.formData.phonePrefix = data.phonePrefix
        this.formData.account = data.account
        this.formData.deptIds = data.deptIds
        this.formData.email = data.email
        this.formData.name = data.name
        this.formData.phone = data.phone
        this.formData.roleIds = data.roleIds
        this.dialogTitle = '修改账号'
        this.dialogVisible = true
      })
    },
    // 停用
    disableEvent(row) {
      let params = {
        id: row.id,
        status: 'DISABLE'
      }
      editStatus(params).then(res => {
        if (res.code === 200) {
          this.$message.success('账号停用成功')
          this.queryList()
        } else {
          this.$message.success(res.msg)
        }
      })
    },
    // 启用
    enableEvent(row) {
      let params = {
        id: row.id,
        status: 'ENABLE'
      }
      editStatus(params).then(res => {
        if (res.code === 200) {
          this.$message.success('账号启用成功')
          this.queryList()
        } else {
          this.$message.success(res.msg)
        }
      })
    },
    // 查询
    async queryList(params) {
      this.listLoading = true
      if (params) {
        this.queryParams = params
      } else {
        params = this.queryParams
      }
      const res = await queryList({ ...params })
      if (res.code === 0) {
        this.dataList = res.data.items
        this.total = res.data.total * 1
        this.tableSummary = res.data.summary
        this.baseColumns = res.data.tableSettingFields || config.baseColumn(this)
        this.path = res.data.path || this.path
        this.colSize = res.data.colSize
        this.initConfig()
      }
      this.listLoading = false
    },
    // 提交数据
    submitData() {
      this.$refs.formData.validate(valid => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '正在保存...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.6)',
            target: document.getElementById('el-dialog')
          })
          if (this.formData.id) {
            userEdit(this.formData).then(res => {
              if (res.code === 200) {
                loading.close()
                this.dialogVisible = false
                this.$message.success('修改账号信息成功')
                this.resetForm()
                this.queryList()
              } else {
                loading.close()
                this.dialogVisible = false
                this.$message.error(res.msg)
              }
            })
          } else {
            userSave(this.formData).then(res => {
              if (res.code === 200) {
                loading.close()
                this.dialogVisible = false
                this.$message.success('新增账号成功')
                this.resetForm()
                this.queryList()
              } else {
                this.$message.error(res.msg)
                loading.close()
                this.dialogVisible = false
              }
            })
          }
        } else {
          return
        }
      })
    },
    // 获取角色列表
    getRoleList() {
      let params = {
        status: 'ENABLE'
      }
      getRoleList(params).then(res => {
        this.roleList = res.data
      })
    },
    // 获取所有的部门列表
    getDeptList() {
      getDeptList().then(res => {
        this.deptList = res.data
      })
    },
    // 角色下拉框值改变
    roleChanged(value) {
      this.formData.roleIds = value.join(',')
    },
    // 部门树形下拉值改变
    inputChange(value, instanceId) {
      this.formData.deptIds = value.join(',')
    },
    // 部门数据格式装换
    /** 转换菜单数据结构 */
    normalizer(node) {
      if (node.childList && !node.childList.length) {
        delete node.childList
      }
      return {
        id: node.id,
        label: node.name,
        children: node.childList
      }
    },
    // 表单重置
    resetForm() {
      this.formData = {
        id: '',
        phonePrefix: '+86',
        account: '', // 系统登录账号
        deptIds: '', //所属部门
        email: '', // 电子邮件
        name: '', //姓名
        phone: '', //电话
        roleIds: '' // 角色
      }
      this.deptsChecked = []
      this.roles = []
    },
    initConfig() {
      this.tableColumns = this.baseColumns.filter(v => v.showField)
      this.searchFields = this.baseColumns.filter(v => v.isSearchShow)
      if (this.searchFields.length === 0) {
        this.searchFields = config.searchFields(this)
      }
      this.sortFields = this.tableColumns
        .filter(v => v.sortable)
        .reduce((prev, item) => {
          // if(item.sortList) prev = prev.concat(item.sortList)
          prev.push({
            title: `${item.title}升序`,
            field: `${item.field}-asc`
          })
          prev.push({
            title: `${item.title}降序`,
            field: `${item.field}-desc`
          })
          return prev
        }, [])
    },
    //保存|修改表格头配置
    async saveTableField(fields) {
      this.tableColumns = fields
      try {
        const res = await columnSetting({
          path: this.path,
          searchFields: this.searchFields,
          tableFields: fields
        })
        this.getTableData()
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改搜索字段配置
    async saveSearchField(fields, colSize) {
      // console.log(fields, colSize)
      this.colSize = colSize
      this.searchFields = fields
      try {
        const res = await columnSetting({
          path: this.path,
          searchFields: fields,
          // tableFields: this.tableColumns,
          colSize
        })
      } catch (error) {
        console.log(error)
      }
    },
    //保存|修改排序字段配置
    async saveSortField(fields) {
      this.sortFields = fields
    },
    // 处理数据
    proxyParams(params) {
      console.log(params)
    },

    //获取表格头配置
    async getTableField() {},
    //获取搜索字段配置
    async getSearchField() {}
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.input-cls {
  width: 260px;
  font-size: 14px;
  // feat: 搜索栏过多收起
  margin-right: 10px;
}
.icon-cls-green {
  color: #67c23a;
}
.dialog-row {
  margin-top: 10px;
}
</style>

<style rel="stylesheet/scss" lang="scss" scoped>
.validFieldClass .el-input__inner {
  border-color: #f56c6c;
}
.el-pagination {
  float: right;
}
.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-ghost,
.sortable-column-demo .vxe-header--row .vxe-header--column.sortable-chosen {
  background-color: #dfecfb;
}
.vxe-table--footer .col-blue {
  background: #f2f5f0;
}
/*单号复制样式 开始*/
.copy-content {
  right: 0.1em;
  top: 0.1em;
  float: right !important;
  position: absolute !important;
}
.copy-content:hover {
  background-color: #1482f0;
  float: right !important;
  height: 1.8em;
  width: 1.8em;
}
.write-color {
  border-color: #f0f0f0;
}
/*单号复制样式 结束*/
.input-width-4 {
  width: 40% !important;
}
.input-width-5 {
  width: 50% !important;
}
.input-width-6 {
  width: 60% !important;
}
.input-width-7 {
  width: 70% !important;
}
.input-width-8 {
  width: 80% !important;
}
.vue-treeselect {
  // position: absolute;
  text-align: left;
  width: 80%;
}

::v-deep .el-form-item__content {
  line-height: none !important;
}
.vue-treeselect {
  text-align: left;
  width: 100%;
}
</style>
